import React from "react";

import "../styles/textointro.css";
const TextoIntro = () => {
  const redirectToWhatsApp = () => {
    window.location.href = "https://wa.me/+5492213995465";
  };
  return (
    <div className="otrocomponent-div">
      <div className="informacion-texto">
        <h1 className="frase-netriup">
          Somos especialistas en <span className="highlight">potenciar</span> y{" "}
          <span className="highlight">optimizar</span> tu negocio
        </h1>
        <p className="parrafo-netriup">
          En Netriup traemos tu idea digital a la realidad. Con una
          combinación experta de diseño web a medida, estrategias de marketing
          digital innovadoras y soluciones tecnológicas avanzadas, garantizamos
          que tu presencia online no solo destaque, sino que también genere
          resultados.<br></br><br></br> Desde el concepto inicial hasta la ejecución final,
          trabajamos mano a mano para asegurar que tu sitio web no solo
          sea visualmente impresionante, sino una herramienta poderosa de
          crecimiento y conexión con tu audiencia. Buscamos ser el puente
          entre tu negocio y su máximo potencial en el mundo digital.
        </p>
        <button className="boton-contactarme" onClick={redirectToWhatsApp}>Contactarme</button>
      </div>
      <div className="testing"></div>
    </div>
  );
};

export default TextoIntro;
