import React, { useState, useEffect } from "react";
import { useRef } from "react";
import cuboblanco from "../images/cuboblanco.png";

import "../styles/proceso.css";
const Proceso = () => {
  const redirectToWhatsApp = () => {
    window.location.href = "https://wa.me/+5492213995465";
  };
  const leftRef = useRef(null);
  const rightRef = useRef(null);
  const [top, setTop] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      // New: Move the image down as the user scrolls
      setTop(window.scrollY * 0.1); // Adjust multiplier as needed
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("sliding-in-left");
          }
        });
      },
      { rootMargin: "0px", threshold: 0.5 }
    );

    const observer2 = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("sliding-in-right");
          }
        });
      },
      { rootMargin: "0px", threshold: 0.5 }
    );

    if (leftRef.current) {
      observer.observe(leftRef.current);
    }

    if (rightRef.current) {
      observer2.observe(rightRef.current);
    }

    return () => {
      if (leftRef.current) {
        observer.unobserve(leftRef.current);
      }
      if (rightRef.current) {
        observer2.unobserve(rightRef.current);
      }
    };
  }, []);
  const [selected, setSelected] = useState(1);

  // Define texts for each button
  const texts = {
    1: "Contacto",
    2: "Plan de Accion",
    3: "Objetivos y Diseño",
    4: "Desarrollo",
    5: "Entrega y Seguimiento",
  };

  const textsRight = {
    1: "Iniciá tu transformación digital con solo un clic. Ponete en contacto con nosotros y compartí tu visión para empezar a darle forma a tu proyecto",
    2: "Definimos un plan de acción personalizado, identificando las estrategias y tecnologías que catapultarán tu negocio en el mundo digital",
    3: "Establecemos objetivos claros y creamos un diseño único que refleje la esencia de tu marca, garantizando que cada elemento esté alineado con tus metas",
    4: "Convertí tus ideas en realidad. Nuestro equipo de expertos desarrolla tu sitio con las últimas tecnologías, asegurando calidad y funcionalidad",
    5: "Avanzamos tu proyecto al mundo, pero nuestro trabajo no termina ahí. Ofrecemos mantenimiento y seguimiento continuo para asegurar el éxito a largo plazo de tu sitio web",
  };

  // Handler for button click
  const handleButtonClick = (number) => {
    setSelected(number);
  };
  return (
    <div className="siguientecomponent-div">
      <div className="div-titulo-como-trabajamos">
        <h1 className="titulo-trabajamos">Como trabajamos</h1>
      </div>
      <div className="outside-div-two-sides">
        <div className="outside-div-left" ref={leftRef}>
          <div className="botones-con-titulos">
            {[1, 2, 3, 4, 5].map((number) => (
              <div className="individual-texto" key={number}>
                <button
                  className={`boton-selector ${
                    selected === number ? "boton-selector-selected" : ""
                  }`}
                  onClick={() => handleButtonClick(number)}
                >
                  {number}
                </button>
                <h2>{texts[number]}</h2>
              </div>
            ))}
            <button className="boton-paso-uno" onClick={redirectToWhatsApp}>Paso 1 Acá!</button>
          </div>
        </div>
        <div className="outside-div-right" ref={rightRef}>
          <img
            className="cuboblanco6"
            style={{
              //filter: `blur(${blur}px)`,
              // transform: `scale(${scale})`,
              position: "absolute", // Needed to allow for top positioning
              top: `${top + 200}px`,
            }}
            src={cuboblanco}
          />{" "}
          <img
            className="cuboblanco7"
            style={{
              //filter: `blur(${blur}px)`,
              // transform: `scale(${scale})`,
              position: "absolute", // Needed to allow for top positioning
              top: `${top - 40}px`,
            }}
            src={cuboblanco}
          />{" "}
          <img
            className="cuboblanco8"
            style={{
              //filter: `blur(${blur}px)`,
              // transform: `scale(${scale})`,
              position: "absolute", // Needed to allow for top positioning
              top: `${top - 200}px`,
            }}
            src={cuboblanco}
          />{" "}
          <div className="texto-derecha">
            {selected && (
              <h3 className="texto-dentro-de-circulos">
                {textsRight[selected]}
              </h3>
            )}
          </div>
        </div>
      </div>
      <div
        className="testin  height: 100vh;
g-dos"
      ></div>
    </div>
  );
};

export default Proceso;
