import React from "react";
import Blogs from "../information/blogs";
import { Navigate, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import "../styles/indblog.css";

function MostrarBlog(id, titulo, contenido) {
  if (id === 1) {
    return (
      <div className="ind-blog-result">
        <h1 className="blog-titulo">{titulo}</h1>
        <p className="parrafo-blog">
          En el mundo digital de hoy, tener presencia online no es solo una
          opción, es una necesidad imperativa para cualquier negocio que aspire
          a crecer y prosperar. Un sitio web se convierte en el escaparate
          virtual de tu empresa, accesible para clientes potenciales las 24
          horas del día, los 7 días de la semana. Pero, ¿alguna vez te has
          preguntado exactamente cómo un sitio web puede impulsar tus ventas
          online? Vamos a desglosarlo!
          <br></br>
          <br></br>
          <h3 className="subtitulo-blog">Visibilidad Constante</h3>
          <br></br>
          En primer lugar, un sitio web aumenta exponencialmente tu visibilidad.
          No importa cuán pequeño sea tu negocio; un sitio web bien diseñado te
          pone en el mapa global, permitiendo que personas de cualquier parte
          del mundo te encuentren, conozcan tus productos o servicios y, lo más
          importante, realicen compras.
          <br></br>
          <br></br>
          <h3 className="subtitulo-blog">Credibilidad y Confianza</h3>
          <br></br>
          Un sitio web profesional transmite seriedad y compromiso. Los
          consumidores tienden a confiar más en negocios que tienen una
          presencia online sólida y bien establecida. Al ofrecer contenido
          relevante, testimonios y casos de éxito, estás construyendo una
          relación de confianza con tus visitantes, lo cual es fundamental para
          convertirlos en clientes.
          <br></br>
          <br></br>
          <h3 className="subtitulo-blog">Marketing Efectivo</h3>
          <br></br>
          Con un sitio web, las oportunidades de marketing se multiplican. Desde
          SEO (optimización para motores de búsqueda) que ayuda a tu sitio a ser
          encontrado por las personas adecuadas, hasta campañas de publicidad
          digital dirigidas específicamente a tu público objetivo. Cada
          visitante es un cliente potencial que llegó a ti buscando lo que
          ofreces.
          <br></br>
          <br></br>
          <h3 className="subtitulo-blog">Análisis y Mejora Continua</h3>
          <br></br>
          Un sitio web te permite rastrear y analizar el comportamiento de tus
          visitantes: qué les interesa, cuánto tiempo pasan en tu sitio y qué
          les motiva a realizar una compra. Esta información es vital para
          ajustar tus estrategias, mejorar tu oferta y, en última instancia,
          aumentar tus ventas.
          <br></br>
          <br></br>
          <h3 className="subtitulo-blog">¿Cómo Nosotros Mejoramos y Aprovechamos Estos Elementos?</h3>
          <br></br>
          En <b>Netriup</b>, entendemos la importancia de cada uno de estos
          aspectos para el éxito de tu sitio web. Por ello, nos enfocamos en:
          <br></br>
          <br></br>

          <b>Diseño Personalizado:</b> Crear un sitio web que refleje la
          identidad de tu marca, atractivo y fácil de navegar para tus usuarios.
          <br></br>
          <br></br>

          <b>Optimización SEO:</b> Asegurar que tu sitio no solo sea visible,
          sino que también alcance los primeros puestos en los resultados de
          búsqueda.
          <br></br>
          <br></br>

          <b>Contenido de Calidad:</b> Ayudarte a generar contenido relevante y
          valioso que resuene con tu audiencia y construya confianza.<br></br>{" "}
          <b>Análisis Web:</b> Implementar herramientas de análisis para
          entender mejor a tu audiencia y optimizar continuamente la experiencia
          de usuario.
          <br></br>
          <br></br>

          <b>Estrategias de Conversión:</b> Desarrollar llamados a la acción
          efectivos y páginas de destino que conviertan visitantes en clientes.
          En resumen, un sitio web es mucho más que solo una tarjeta de
          presentación digital; es una herramienta de ventas multifacética que,
          si se utiliza correctamente, puede impulsar significativamente tus
          ventas online.
          <br></br>
          <br></br>
          En <b>Netriup</b>, estamos aquí para asegurarnos de que aproveches al
          máximo esta poderosa herramienta.
          <br></br>
          <br></br>

          <b>Contactanos</b> para comenzar a transformar tu presencia online hoy
          mismo.
        </p>
      </div>
    );
  }
}

const IndBlog = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const blogs = Blogs[id - 1];
  return (
    <div className="container-blog">
      {MostrarBlog(blogs.id, blogs.titulo, blogs.contenido)}
    </div>
  );
};

export default IndBlog;
