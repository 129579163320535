import React from "react";
import "../styles/trabajarconnosotros.css";
const TrabajarConNosotros = () => {
  const redirectToWhatsApp = () => {
    window.location.href = "https://wa.me/+5492213995465";
  };
  return (
    <div className="outside-div-trabajar-con-nosotros">
      <div className="testing-dos"></div>
      <h1 className="titulo-trabajar-con-nosotros">
        Porqué trabajar con nosotros?
      </h1>
      <div className="div-texto">
        <div className="beneficios-div">
          <h3 className="beneficio-texto">Experiencia y Profesionalismo</h3>
          <h3 className="beneficio-texto">Tecnología de Vanguardia</h3>
          <h3 className="beneficio-texto">Soluciones Personalizadas</h3>
          <h3 className="beneficio-texto">Compromiso con la Excelencia</h3>
          <h3 className="beneficio-texto">Innovación Constante</h3>
          <h3 className="beneficio-texto">Asistencia y Sostenibilidad</h3>
          <h3 className="beneficio-texto">Eficiencia y Puntualidad</h3>
          <h3 className="beneficio-texto">Potenciamos Empresas y Emprendimientos</h3>
          <h3 className="beneficio-texto">Soporte Continuo</h3>
        </div>
      </div>
      <div className="boton-div-trabajar">
        <button className="boton-contactarme" onClick={redirectToWhatsApp}>Estoy Listo!</button>
      </div>
    </div>
  );
};

export default TrabajarConNosotros;
