import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import "./App.css";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import TextoIntro from "./components/TextoIntro";
import Proceso from "./components/Proceso";
import TrabajarConNosotros from "./components/TrabajarConNosotros";
import Servicios from "./components/Servicios";
import Contacto from "./components/Contacto";
import Blog from "./components/Blog";
import Footer from "./components/Footer";
import Whatsappbutton from "./components/WhatsappButton";
import IndBlog from "./components/IndBlog";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <div className="App">
                <Home />
                <Navbar />
                <Whatsappbutton />
                <TextoIntro />
                <Proceso />
                <TrabajarConNosotros />
                <Footer />
              </div>
            }
          />
          <Route
            path="/servicios"
            element={
              <div className="App">
                <Home />
                <Navbar />
                <Whatsappbutton />
                <Servicios />
                <Footer />
              </div>
            }
          />
          <Route
            path="/contacto"
            element={
              <div className="App">
                <Home />
                <Navbar />
                <Whatsappbutton />
                <Contacto />
                <Footer />
              </div>
            }
          />
          <Route
            path="/blog"
            element={
              <div className="App">
                <Home />
                <Navbar />
                <Whatsappbutton />
                <Blog />
                <Footer />
              </div>
            }
          />
                    <Route
            path="/blog/:id"
            element={
              <div className="App">
                <Home />
                <Navbar />
                <Whatsappbutton />
                <IndBlog />
                <Footer />
              </div>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
