import React, { useState } from "react";
import "../styles/contactform.css";
function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Usa fetch para enviar los datos a Formspree
    fetch("https://formspree.io/f/meqylkvv", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        // Aquí puedes manejar la respuesta, como mostrar un mensaje de éxito
      })
      .catch((error) => {
        console.error("Error:", error);
        // Manejar el error
      });
  };

  return (
    <div className="outside-div-form">
      <form onSubmit={handleSubmit}>
        <div className="form-div">
          <label>
            Nombre
            <br></br>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </label>
          <label>
            Dejanos tu Email
            <br></br>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </label>
          <label>
            Mensaje
            <br></br>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
            />
          </label>
          <button className="whatsapp-button email-button" type="submit">Enviar</button>
        </div>
      </form>
    </div>
  );
}

export default ContactForm;
