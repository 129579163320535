import React, { useState } from "react";
import "../styles/contacto.css";
import { InlineWidget } from "react-calendly";
import ContactForm from "./ContactForm";

const Contacto = () => {
  const redirectToWhatsApp = () => {
    window.location.href = "https://wa.me/+5492213995465";
  };

  return (
    <div className="outside-div-contacto">
      <h1 className="contacto-title">Contacto</h1>
      <div className="contactos-disponibles-div">
        <div className="div-whatsapp-button">
          <p className="texto-contacto"> Podes contactarnos por</p>

          <button className="whatsapp-button" onClick={redirectToWhatsApp}>
            Whatsapp
          </button>
        </div>
        <div className="email-section">
          <p className="texto-contacto"> Envianos un email a </p>
          <p className="texto-contacto email"> netriup@gmail.com </p>
        </div>
        <div className="formulario-div-contacto">
          <p className="texto-contacto"> O través del formulario:</p>
          <ContactForm />
        </div>
        <div className="calendly">
          <p className="texto-contacto"> O reservar una llamada online:</p>
          <InlineWidget
            style={{ minWidth: "300px" }}
            url="https://calendly.com/netriup"
          />
        </div>
      </div>
    </div>
  );
};

export default Contacto;
