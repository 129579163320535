import React, { useEffect, useRef } from "react";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import cuboblanco from "../images/cuboblanco.png";

import "../styles/footer.css";

const Footer = () => {
  const redirectToWhatsApp = () => {
    window.location.href = "https://wa.me/+5492213995465";
  };
  const redirectToInstagram = () => {
    window.location.href = "https://www.instagram.com/netriup/";
  };
  const redirectToFacebook = () => {
    window.location.href =
      "https://www.facebook.com/profile.php?id=61557452465813";
  };

  const leftRef = useRef(null);
  const middleRef = useRef(null);
  const rightRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Revisa si el elemento es visible
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.5 } // Ajusta este valor según necesites que tanto del elemento debe estar visible antes de activar la animación
    );

    // Observa los elementos
    if (leftRef.current) {
      observer.observe(leftRef.current);
    }
    if (middleRef.current) {
      observer.observe(middleRef.current);
    }
    if (rightRef.current) {
      observer.observe(rightRef.current);
    }

    return () => {
      // Desconecta el observer al desmontar el componente para limpiar
      if (leftRef.current) {
        observer.unobserve(leftRef.current);
      }
      if (middleRef.current) {
        observer.unobserve(middleRef.current);
      }
      if (rightRef.current) {
        observer.unobserve(rightRef.current);
      }
    };
  }, []); // Este efecto se ejecuta solo una vez al montar el componente

  return (
    <div className="outside-div-footer">
      <div className="left-side" ref={leftRef}>
        <img src={cuboblanco}></img>
      </div>
      <div className="middle-side" ref={middleRef}>
        <div className="redes-netriup">
          <button className="button-redes" onClick={redirectToInstagram}>
            <FaInstagram size={20} />
          </button>
          <button className="button-redes">
            <FaWhatsapp size={20} onClick={redirectToWhatsApp} />
          </button>
          <button className="button-redes">
            <FaFacebook size={20} onClick={redirectToFacebook} />
          </button>
        </div>
        <h1>Netriup</h1>
      </div>
      <div className="right-side" ref={rightRef}>
        <img src={cuboblanco}></img>
      </div>
    </div>
  );
};

export default Footer;
