import React from "react";
import "../styles/servicios.css";

const Servicios = () => {
  return (
    <div className="outside-div-servicios">
      <h1 className="servicios-title">Servicios</h1>
      <div className="three-cards">
        <div className="cards">
          <h1 className="titulo-de-servicio">
            Diseño y Desarrollo Web Personalizado
          </h1>
          <p className="descripcion-de-servicio">
            Transformamos tus ideas en realidad con soluciones web a medida.
            Nuestro equipo se especializa en crear sitios web únicos desde cero,
            enfocándonos en la usabilidad, el diseño moderno y la experiencia
            del usuario. Ya sea para una pequeña empresa o una corporación
            grande, trabajamos contigo en cada paso del proceso para asegurar
            que tu sitio web no solo se vea bien, sino que también funcione
            perfectamente en todas las plataformas y dispositivos.
          </p>
        </div>
        <div className="cards">
          <h1 className="titulo-de-servicio">
            Desarrollo de Applicacion Web Personalizada para Empresas y
            Emprendimientos
          </h1>
          <p className="descripcion-de-servicio">
            Ofrecemos soluciones innovadoras en el desarrollo de aplicaciones
            web personalizadas, diseñadas específicamente para empresas y
            emprendimientos. Transformamos tus ideas en soluciones digitales
            eficientes, seguras y escalables, adaptadas a las necesidades únicas
            de tu negocio para impulsar su crecimiento y éxito en el mercado
            digital."
          </p>
        </div>
        <div className="cards">
          <h1 className="titulo-de-servicio">Desarrollo de E-commerce</h1>
          <p className="descripcion-de-servicio">
            Lleva tu tienda online al siguiente nivel con nuestro servicio
            especializado de desarrollo de e-commerce. Ofrecemos soluciones
            completas para tu negocio en línea, incluyendo diseño de tiendas
            atractivas, integración de sistemas de pago seguros, gestión de
            inventarios, y optimización de la experiencia de compra. Ya sea que
            estés empezando desde cero o quieras mejorar tu tienda existente,
            estamos aquí para ayudarte a crecer y alcanzar tus metas
            comerciales.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Servicios;
