import React, { useState } from "react";
import "../styles/blog.css";
import blogs from "../information/blogs";
import { useNavigate } from "react-router-dom";

const Blog = () => {
  const navigate = useNavigate();

  const [blogsLista, setBlogsLista] = useState(blogs);

  return (
    <div className="outside-div-blog">
      <h1 className="blog-title">Blog</h1>
      <div className="div-outside-blogs">
        {blogsLista.map((oficina) => {
          const { id, titulo, contenido } = oficina;
          return (
            <div
              className="ind-blog"
              key={id}
              onClick={() => navigate("/blog/" + id)}
            >
              <h1 className="titulo-blogs">{titulo}</h1>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Blog;
