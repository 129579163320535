import React, { useState } from "react";
import "../styles/navbar.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
import { IoHome } from "react-icons/io5";
import { IoMdContact, IoMdOutlet } from "react-icons/io";
import { BsWhatsapp } from "react-icons/bs";
import { TfiWrite } from "react-icons/tfi";
import { MdOutlineMiscellaneousServices } from "react-icons/md";

import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";

function Navbar(props) {
  const navRef = useRef();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  //const hideNavbar =() => {
  // navRef.current.classList.toggle("responsive_nav");
  //}

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  // Call this function to hide the navbar
  const hideNavbar = () => {
    scrollToTop();
    navRef.current.classList.remove("responsive_nav");
  };

  return (
    <header className="header-div">
      <div className="out-div-nav-bar">
        <div className="navigation-bar" style={props.style}>
          <nav ref={navRef} className="menu">
            <Link className="menu-text" to="/" onClick={hideNavbar}>
              HOME
            </Link>
            <Link className="menu-text" to="/servicios" onClick={hideNavbar}>
              SERVICIOS
            </Link>
            <Link className="menu-text" to="/contacto" onClick={hideNavbar}>
              CONTACTO
            </Link>
            <Link className="menu-text" to="/blog" onClick={hideNavbar}>
              BLOG
            </Link>
            <Link
              className="menu-text"
              to="https://wa.me/+5492213995465"
              onClick={hideNavbar}
            >
              <a
                href="https://wa.me/+5492213995465"
                className="whatsapp-icon-menu"
                target="_blank"
              >
                {" "}
                <FaWhatsapp className="whatsapp-icon-navbar" />
              </a>
            </Link>
            <div className="nav-btn-div">
              <button className="nav-btn nav-close-btn" onClick={showNavbar}>
                <FaTimes />
              </button>
            </div>
          </nav>
          <div className="barra-navegacion-responsive">
              <Link className="menu-text-responsive" to="/blog" onClick={hideNavbar}>
              <TfiWrite size={30} />
              </Link>
              <Link className="menu-text-responsive" to="/contacto" onClick={hideNavbar}>
                <IoMdContact size={30} />
              </Link>
              <Link className="menu-text-responsive" to="/" onClick={hideNavbar}>
                <IoHome size={40} />
              </Link>
              <Link className="menu-text-responsive" to="/servicios" onClick={hideNavbar}>
                <MdOutlineMiscellaneousServices size={30} />
              </Link>
              <Link className="menu-text-responsive" to="https://wa.me/+5492213995465" onClick={hideNavbar}>
                <BsWhatsapp size={30} />
              </Link>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
