import React, { useState, useEffect } from "react";
import "../styles/home.css";
import "../App.css";
import cuboblanco from "../images/cuboblanco.png";
const Home = () => {
  const [left, setLeft] = useState(0);
  const [right, setRight] = useState(0);
  const [blur, setBlur] = useState(0);
  const [scale, setScale] = useState(1);
  const [top, setTop] = useState(200);
  const cubos = [{ id: 1 }, { id: 2 }, { id: 3 }];

  useEffect(() => {
    const handleScroll = () => {
      // Existing scroll handling
      setLeft(window.scrollY * 0.9 - 200);
      setRight(window.scrollY * 0.9);
      setBlur(window.scrollY * 0.9);
      setScale(1 + window.scrollY * 0.001); // Adjusted for realistic scaling

      // New: Move the image down as the user scrolls
      setTop(200 + window.scrollY * 0.5); // Adjust multiplier as needed
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="App">
      <div
        className="top-netriup"
        style={{ left: `${left}px` }}
        id="netriuptops"
      ></div>
      <div className="netriup-banner">
        <h1
        className="titulo-netriup"
          style={{
            filter: `blur(${blur}px)`,
            // transform: `scale(${scale})`,
            //position: "absolute", // Needed to allow for top positioning
            top: `${top}px`,
          }}
        >
          Netriup
        </h1>
        <img
          className="cuboblanco"
          style={{
            //filter: `blur(${blur}px)`,
            // transform: `scale(${scale})`,
            position: "absolute", // Needed to allow for top positioning
            top: `${top+200}px`,
          }}
          src={cuboblanco}
        />{" "}
                <img
          className="cuboblanco1"
          style={{
            //filter: `blur(${blur}px)`,
            // transform: `scale(${scale})`,
            position: "absolute", // Needed to allow for top positioning
            top: `${top+100}px`,
          }}
          src={cuboblanco}
        />{" "}
                <img
          className="cuboblanco2"
          style={{
            //filter: `blur(${blur}px)`,
            // transform: `scale(${scale})`,
            position: "absolute", // Needed to allow for top positioning
            top: `${top-10}px`,
          }}
          src={cuboblanco}
        />{" "}
                        <img
          className="cuboblanco4"
          style={{
            //filter: `blur(${blur}px)`,
            // transform: `scale(${scale})`,
            position: "absolute", // Needed to allow for top positioning
            top: `${top+350}px`,
          }}
          src={cuboblanco}
        />{" "}
      </div>
      <div
        className="bottom-netriup"
        style={{ right: `${right}px` }}
        id="netriuptops"
      ></div>
    </div>
  );
};

export default Home;
