import React from "react";
import "../styles/whatsappbutton.css";
import { FaWhatsapp } from "react-icons/fa";
function Whatsappbutton() {
  return (
    <div>
      <a
        href="https://wa.me/+5492213995465"
        className="whatsapp"
        target="_blank"
      >
        {" "}
        <FaWhatsapp className="whatsapp-icon"/>
      </a>
    </div>
  );
}

export default Whatsappbutton;